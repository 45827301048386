import React from 'react';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Header from '../../components/header';
import { useGlobalStyles } from '../../utils/styles';
import { Card, CardContent, CardMedia, Grid, Typography, useTheme } from '@material-ui/core';
import UpdatedInfo from '../../components/templates/UpdatedInfo';
import { getApiUrl } from '../../utils/functions';

import { infraestructuras} from "../../utils/constants"


interface IInforme {
  image: string,
  name: string,
  to: string
}

const Galeria = () => {
  const theme = useTheme();
  const globalClasses = useGlobalStyles();
  const updatedAt = 'Agosto 2023';

  const galeria: IInforme[] = [
    { image:  require('../../assets/images/gallery/infraestructura/0.jpg'),
      name: 'Infraestructura 0',
      to:   require('../../assets/images/gallery/infraestructura/0.jpg')
    },
    {
      image: require('../../assets/images/gallery/infraestructura/1.jpg'),
      name: 'Fe de erratas del informe 2020',
      to:   require('../../assets/images/gallery/infraestructura/1.jpg')
    }
   
  ];

  return (
    <Layout>
      <SEO title='Galería Fotográficas ISSET' />

      <div className={globalClasses.container}>
        <Header title='Galería Fotográficas ISSET' />

        <div className={globalClasses.content}>
          <Typography>Aquí podrás ver galería fotográficas del Instituto de Seguridad Social del Estado de Tabasco.</Typography>

          <Grid container spacing={3} style={{ marginTop: theme.spacing(3) }}>
            {infraestructuras.map((informe, index) =>
              <Grid key={index} item xs={12} sm={4} md={2}>
                <a href={informe.to} target="_blank" rel='noopener noreferrer' style={{ textDecoration: 'none' }}>
                  <Card>
                    <CardMedia
                      title={informe.name}
                      image={informe.to}
                      style={{
                        height: 245,
                        maxHeight: 245
                      }}
                    />
                    <CardContent style={{ paddingBottom: 16 }}>
                      <Typography color='primary'>{informe.name}</Typography>
                    </CardContent>
                  </Card>
                </a>
              </Grid>
            )}
          </Grid>
        </div>

        <UpdatedInfo updatedAt={updatedAt} />
      </div>
    </Layout>
  );
};

export default Galeria;